import * as React from 'react';
import Helmet from 'react-helmet';

import Logo from 'assets/svg/averlast-logo.svg';
import Dribbble from 'assets/svg/dribbble.svg';
import Github from 'assets/svg/github.svg';
import Instagram from 'assets/svg/instagram.svg';

import { helmet } from 'utils/helmet';
import { Header } from 'components/header/Header';
import { Link as HeaderLink } from 'components/header/Link';
import { Footer } from 'components/footer/Footer';
import { Devtools } from 'components/devtools/Devtools';

import s from './AppLayout.scss';

interface IAppLayoutProps {
  children: React.ReactNode;
}

const isDev = process.env.NODE_ENV === 'development';

export default ({ children }: IAppLayoutProps) => (
  <div className={s.layout}>
    <Helmet {...helmet} />

    <Header>
      {/* <HeaderLink
        name="About"
        to="/about"
      /> */}
{/*
      <HeaderLink
        name="Labs"
        to="/about"
      /> */}

      {/* <HeaderLink
        name="Culture"
        to="/culture"
      /> */}

      <HeaderLink
        name="Contact Us"
        to="mailto:newbusiness@averlast.co"
      />

    </Header>

    {children}

    {/* <Footer
      logo={<Logo />}
      social={[
        { icon: <Dribbble />, to: 'https://dribbble.com/ueno' },
        { icon: <Github />, to: 'https://github.com/ueno-llc' },
        { icon: <Instagram />, to: 'https://www.instagram.com/uenodotco' },
      ]}
    /> */}


  </div>
);
