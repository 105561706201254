module.exports = [{
  plugin: require('/opt/render/project/src/node_modules/gatsby-plugin-layout/gatsby-browser.js'),
  options: {
    "plugins": [],
    "component": "/opt/render/project/src/src/components/app-layout/AppLayout.tsx"
  }
}, {
  plugin: require('/opt/render/project/src/node_modules/gatsby-plugin-fathom/gatsby-browser.js'),
  options: {
    "plugins": [],
    "trackingUrl": "cdn.usefathom.com",
    "siteId": "KSAAWCOZ"
  }
}, {
  plugin: require('/opt/render/project/src/gatsby-browser.js'),
  options: {
    "plugins": []
  }
}];