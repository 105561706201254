// prefer default export if available
var preferDefault = function preferDefault(m) {
  return m && m.default || m;
};

exports.components = {
  "component---src-pages-404-tsx": function componentSrcPages404Tsx() {
    return import("/opt/render/project/src/src/pages/404.tsx"
    /* webpackChunkName: "component---src-pages-404-tsx" */
    );
  },
  "component---src-pages-about-tsx": function componentSrcPagesAboutTsx() {
    return import("/opt/render/project/src/src/pages/about.tsx"
    /* webpackChunkName: "component---src-pages-about-tsx" */
    );
  },
  "component---src-pages-culture-tsx": function componentSrcPagesCultureTsx() {
    return import("/opt/render/project/src/src/pages/culture.tsx"
    /* webpackChunkName: "component---src-pages-culture-tsx" */
    );
  },
  "component---src-pages-index-tsx": function componentSrcPagesIndexTsx() {
    return import("/opt/render/project/src/src/pages/index.tsx"
    /* webpackChunkName: "component---src-pages-index-tsx" */
    );
  }
};